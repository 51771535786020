.columnContainer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 0 10px;
  &:hover {
    border-radius: 10px;
    background: #f5f5f5;
  }
  &:hover .column .item {
    border-bottom: 0;
  }
  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    .item {
      margin: 5px 0;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      gap: 30px;
      border-bottom: 1px solid rgba(192, 204, 218, 0.3);
      .number {
        color: #4a5568;
        font-size: 20px;
        font-weight: 700;
      }
      .userImg {
        height: 55px;
        width: 55px;
        border-radius: 100%;
        object-fit: fill;
      }
      .varified {
        position: absolute;
        bottom: 0;
        right: -10px;
      }
      .title {
        color: #131516;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
      .subTxt {
        color: #a0aec0;
        font-size: 12px;
        font-weight: 500;
        @media screen and (max-width: 1199px) {
          font-size: 11px;
        }
      }
      .price {
        color: #4a5568;
        font-size: 12px;
      }
      .amount {
        color: #029cf5;
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
      }
      .polygonTxt {
        color: #4a5568;
        font-size: 12px;
        font-weight: 500;
        padding: 0 24px;
      }
    }
  }
  .friendImg {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    object-fit: cover;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid #ccc;
  }
  .plusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px; /* Adjust size as needed */
    height: 26px; /* Adjust size as needed */
    background-color: #abb0b5; /* Adjust background color as needed */
    color: #fff; /* Adjust text color as needed */
    border-radius: 50%;
    font-size: 12px; /* Adjust font size as needed */
    border: 1px solid #ccc;
  }
}
