// Header Dark
.header {
  backdrop-filter: blur(61px);
  // background-color: #f5f7fa;
  transition: 0.3s ease-in-out;
  position: fixed;
  width: 100%;
  z-index: 99;
  padding: 10px 0;

  .barIcon {
    color: #fff;
  }
  .logo {
    // margin-top: 8px;
    color: #000;
    position: relative;
    top: 5px;
    svg {
      path {
        fill: #ffff;
        &:first-child {
          fill: #fff;
        }
      }
      @media screen and (max-width: 400px) {
        height: 20px;
        width: 100%;
      }
    }
  }
  .headerOuter {
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
    @media screen and (max-width: 991px) {
      flex-direction: row;
    }
  }
  .menuOuter {
    list-style: none;
    padding-left: 0px;
    gap: 30px;
    @media screen and (max-width: 1399px) {
      padding: 0;
      gap: 12px;
    }
    .menuLik {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      @media screen and (max-width: 1399px) {
        font-size: 15px;
      }
      @media screen and (max-width: 991px) {
        display: none !important;
      }
      &:hover {
        color: #f2f2f2;
      }
    }
    .baseButon {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.18);
      backdrop-filter: blur(28.5px);
      border: none;
      padding: 8px 12px;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        opacity: 0.8;
      }
      @media screen and (max-width: 575px) {
        padding: 0 8px;
        height: 40px;
      }
      .buttonTxt {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        border-right: 1px solid rgba(255, 255, 255, 0.25);
        padding-right: 10px;
      }
      .userImg {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        object-fit: fill;
      }
    }
  }
  .inputOuter {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 13px 15px;
    border-radius: 10px;
    cursor: pointer;
    height: 47px !important;
    span {
      color: #fff;
      font-size: 17px;
      font-weight: 500;
    }
    &:hover {
      opacity: 0.9;
    }
    @media screen and (max-width: 575px) {
      padding: 10px;
    }
    input {
      color: #fff;
    }
    svg {
      path {
        stroke: #fff;
      }
    }
    // input {
    //   width: 100%;
    //   background: transparent;
    //   border: none;
    //   color: #fff;
    //   outline: 0;
    //   font-weight: 500;
    // }
  }
  .authBtn {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  ::placeholder {
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
  }
  .baseButon {
    background: rgba(255, 255, 255, 0.2) !important;
    svg {
      path {
        // fill: #000;
      }
    }
  }
  .connect {
    color: #000 !important;
    @media screen and (max-width: 576px) {
      display: none !important;
    }
    .walletIcon {
      path {
        stroke: #000;
      }
    }
  }
  .join {
    background: rgba(255, 255, 255, 0.2);
    // background-color: #029cf5 !important;
    border-radius: 10px;
    backdrop-filter: blur(28.5px);
    border: none;
    padding: 10px 12px;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 22px;
    }
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
    span {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }
}
.header2 {
  background-color: #f5f7fa;
  transition: 0.3s ease-in-out;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .barIcon {
    color: #000;
  }
  .logo {
    color: #000;
    svg {
      path {
        fill: #000;
        &:first-child {
          fill: #0ea5e9;
        }
      }
    }
  }
  .menuOuter {
    .menuLik {
      color: #000;
      &:hover {
        color: #333;
      }
    }
  }
  .inputOuter {
    background: rgba(0, 0, 0, 0.05);
    span{
      color: #000;
    }
    svg {
      path {
        stroke: #000;
      }
    }
  }
  ::placeholder {
    color: #000;
  }
  .authBtn {
    color: #000 !important;
  }
  .baseButon {
    background: rgba(0, 0, 0, 0.05) !important;
    svg {
      path {
        fill: #000;
      }
    }
  }
  .connect {
    @media screen and (max-width: 576px) {
      display: none !important;
    }
    color: #000 !important;
    .walletIcon {
      path {
        stroke: #000;
      }
    }
  }
  .join {
    background-color: #029cf5 !important;
  }
}
.barIcon {
  display: none;
  color: #fff;
  @media screen and (max-width: 991px) {
    display: block;
  }
}
.sidebarLik {
  display: block;
  margin-bottom: 12px;
  background: #ebebeb;
  color: #6f6f6f;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0;
  width: 100%;
  svg {
    font-size: 24px;
    fill: grey;
    path {
      fill: grey;
    }
  }
}
.userdropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
  svg {
    path {
      fill: #6f6f6f;
      stroke: #6f6f6f;
    }
  }
  span {
    color: #6f6f6f;
    font-size: 16px;
    font-weight: 500;
  }
  .logout {
    color: #d93026;
  }
}
:global {
  .ant-dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
    min-width: 130px;
    max-width: 400px;
  }
}
// .notifyBlock {
//   .ant-dropdown-menu {
//     width: 300px !important;
//   }
// }
.scrolled {
  background-color: #333;
}
// Header Light css
.header2 {
  background-color: #f5f7fa;
  transition: 0.3s ease-in-out;
  .barIcon {
    color: #000;
  }
  .logo {
    color: #000;
    svg {
      path {
        fill: #000;
        &:first-child {
          fill: #0ea5e9;
        }
      }
    }
  }
  .menuOuter {
    .menuLik {
      color: #000;
      &:hover {
        color: #333;
      }
    }
  }
  .inputOuter {
    background: rgba(0, 0, 0, 0.05);
    input {
      color: #000;
    }
    svg {
      path {
        stroke: #000;
      }
    }
  }
  ::placeholder {
    color: #000;
  }
  .authBtn {
    color: #000 !important;
  }
  .baseButon {
    background: rgba(0, 0, 0, 0.05) !important;
    svg {
      path {
        fill: #000;
      }
    }
  }
  .connect {
    @media screen and (max-width: 576px) {
      display: none !important;
    }
    color: #000 !important;
    .walletIcon {
      path {
        stroke: #000;
      }
    }
  }
}
.menuTitle {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 15px 0;
}
.menuItem {
  padding: 5px 0;
  p {
    color: #626262;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    b {
      color: #000;
      margin-right: 5px;
    }
  }
  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 8px 12px;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  .greyBtn {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    color: #242424;
    font-size: 16px;
    font-weight: 500;
  }
}

.notify {
  p {
    font-weight: 600;
    color: #029cf5;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
.seeMoreButton {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: #0ea5e9 !important;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}
