.history {
  padding-top: 68px;

  @media screen and (max-width: 575px) {
    padding-top: 62px;
  }

  .myBannerImg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 280px;
    position: relative;

    @media screen and (max-width: 1399px) {
      height: 200px;
    }

    @media screen and (max-width: 767px) {
      height: 150px;
    }

    @media screen and (max-width: 575px) {
      height: 120px;
    }
  }

  .bannerImg {
    background-image: url(../../assets/images/section/defaultBG.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 280px;
    position: relative;

    @media screen and (max-width: 1399px) {
      height: 200px;
    }

    @media screen and (max-width: 767px) {
      height: 150px;
    }

    @media screen and (max-width: 575px) {
      height: 120px;
    }
  }

  // .bannerImg2 {
  //   background-image: url(../../assets/images/section/orgBg.svg);
  // }
  .bannerImg3 {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .innerContent {
    position: relative;
    z-index: 9;
    bottom: -115px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 1399px) {
      bottom: -60px;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
      bottom: -5px;
    }

    .img {
      width: 90px;
      height: 90px;
      border-radius: 20px;
      object-fit: cover;
      margin-bottom: 5px;

      @media screen and (max-width: 1399px) {
        width: 90px;
        height: 90px;
      }

      @media screen and (max-width: 767px) {
        width: 60px;
        height: 60px;
      }

      @media screen and (max-width: 576px) {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }

    .info {
      color: #fff;
      text-align: center;

      b {
        font-size: 20px;

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }

        @media screen and (max-width: 575px) {
          font-size: 12px;
        }
      }

      span {
        @media screen and (max-width: 991px) {
          font-size: 12px;
        }

        @media screen and (max-width: 767px) {
          font-size: 10px;
        }

        @media screen and (max-width: 575px) {
          font-size: 9px;
        }
      }
    }
  }

  .metaInfo {
    border-radius: 10px;
    background: rgba(15, 146, 204, 0.14);
    padding: 6px 16px;
    color: #0f92cc;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
  }

  .metaInfoInRed {
    border-radius: 10px;
    // background: rgba(242, 9, 5, 0.11);f
    padding: 6px 16px;
    color: red;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    font-style: italic;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      margin-top: -3px;
    }
  }

  .userDetails {
    background-color: #f5f7fa;
    height: 100%;

    .userInfo {
      position: relative;
      top: -50px;

      @media screen and (max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }

    .userImg {
      height: 170px;
      min-width: 170px;
      width: 170px;
      background-color: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 6px solid #f5f7fa;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media screen and (max-width: 1399px) {
        height: 140px;
        min-width: 140px;
        width: 140px;
      }

      @media screen and (max-width: 575px) {
        height: 80px;
        min-width: 80px;
        width: 80px;
        border: 2px solid #f5f7fa;
      }

      img {
        border-radius: 100%;
        height: 160px;
        min-width: 160px;
        width: 160px;
        object-fit: cover;
        object-position: top;

        @media screen and (max-width: 1399px) {
          height: 140px;
          min-width: 140px;
          width: 140px;
        }

        @media screen and (max-width: 575px) {
          height: 80px;
          min-width: 80px;
          border: 2px solid #f5f7fa;
          width: 80px;
        }
      }

      span {
        color: #000;
        font-size: 100px;
        font-weight: 900;
        position: relative;
        bottom: 12px;
      }
    }

    .varified {
      @media screen and (max-width: 575px) {
        height: 20px;
        margin-top: 5px;
      }
    }

    .orgImg {
      height: 170px;
      min-width: 170px;
      width: 170px;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 6px solid #f5f7fa;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media screen and (max-width: 1399px) {
        height: 140px;
        min-width: 140px;
        width: 140px;
      }

      @media screen and (max-width: 575px) {
        height: 80px;
        min-width: 80px;
        width: 80px;
        border: 2px solid #f5f7fa;
      }

      img {
        border-radius: 10px;
        object-fit: fill;
        height: 160px;
        min-width: 160px;
        object-position: top;
        width: 160px;

        @media screen and (max-width: 1399px) {
          height: 130px;
          min-width: 130px;
          width: 130px;
        }

        @media screen and (max-width: 575px) {
          height: 70px;
          min-width: 70px;
          border: 2px solid #f5f7fa;
          width: 70px;
        }
      }

      span {
        color: #000;
        font-size: 100px;
        font-weight: 900;
        position: relative;
        bottom: 12px;
      }
    }

    .userName {
      color: #000;
      font-size: 34px;
      font-weight: 500;
      margin: 0;
      line-height: normal;
      word-break: break-word;
      max-width: 800px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 10px;

      @media screen and (max-width: 1399px) {
        font-size: 30px;
      }

      @media screen and (max-width: 1199px) {
        font-size: 30px;
        max-width: 500px;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
        max-width: 300px;
      }

      @media screen and (max-width: 575px) {
        font-size: 20px;
        max-width: 200px;
        padding-top: 5px;
      }
    }

    .subName {
      color: rgba(0, 0, 0, 0.7);
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      line-height: normal;

      b {
        color: #000;
      }
    }

    .socialIcons {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }

    .outer {
      border-right: 1px solid rgba(0, 0, 0, 0.2);

      @media screen and (max-width: 575px) {
        border-right: 0;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
      }

      .flagIcon {
        font-size: 22px;
        margin-top: 7px;
      }

      img,
      svg {
        &:hover {
          transition: 0.3s ease-in-out;
          transform: scale(1.15);
        }
      }
    }

    .blueBtn {
      border-radius: 10px;
      background: #0ea5e9;
      backdrop-filter: blur(13px);
      color: #fafafa;
      font-size: 16px;
      font-weight: 600;
      border: none;
      padding: 13px 16px;
      border: none;
    }

    .items {
      margin-top: -20px;

      @media screen and (max-width: 575px) {
        margin-left: 20px;
      }

      .item {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }

      .value {
        color: #000;
        font-size: 16px;
        font-weight: 700;

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }

    .desc {
      color: #0e0e0e;
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 24px;

      @media screen and (max-width: 1399px) {
        font-size: 14px;
      }

      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
    }
  }

  .coll_name {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 991px) {
      font-size: 20px;
    }

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }

  .owner_name {
    color: #e7e7e7;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-weight: 600;
      color: #fff;
    }

    @media screen and (max-width: 991px) {
      font-size: 20px;
    }

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.connect {
  color: #0ea5e9;

  @media screen and (max-width: 991px) {
    display: none !important;
  }
}

.authBtn {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.connectBtn {
  color: #0ea5e9;
  font-size: 16px;
  font-weight: 600;

  @media screen and (max-width: 576px) {
    width: 120px;
  }
}

.baseButon {
  border-radius: 10px;
  // background: #ff0000;
  backdrop-filter: blur(28.5px);
  border: 1px solid #0ea5e9;
  padding: 10px 15px;
  color: black;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 575px) {
    padding: 0 8px;
  }

  .buttonTxt {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding-right: 10px;
  }

  .userImg {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.blueButton {
  border-radius: 10px;
  background: #0ea5e9;
  backdrop-filter: blur(28.5px);
  border: none;
  // border: 1px solid #0ea5e9;
  padding: 10px 15px !important;
  color: black;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 575px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 575px) {
    padding: 0 8px;
  }

  .buttonTxt {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding-right: 10px;
  }

  .userImg {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.whiteButton {
  border-radius: 10px;
  background: white;
  backdrop-filter: blur(28.5px);
  border: 1px solid #0ea5e9;
  padding: 10px 15px;
  color: black;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  // @media screen and (max-width: 575px) {
  //   padding: 0 8px;
  // }
  .buttonTxt {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding-right: 10px;
  }

  .userImg {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.icons {
  margin-top: 8px;
  font-size: 25px;
  color: black;
}

.showBtn {
  background: transparent;
  border: none;
  color: #009ceb;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.setting {
  .settingIcon {
    path {
      fill: transparent;
      stroke: #fff;
    }
  }

  color: #fff !important;
}

.profileButtons {
  display: flex;
  justify-content: flex-end;
  /* Aligns items to the right */
  align-items: center;
}

.profileButtons>button {
  margin-right: 10px;
  /* Adjust the value as needed */
}

.linkBtn {
  background-color: #0ea5e9;
  border: 1px solid #0ea5e9;
  color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 500;
}

.rejectBtn {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  padding: 10px 15px;

}

.modalContent {
  word-break: break-word;

  h1 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }

  p {
    color: #777e90;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .btn {
    border-radius: 10px;
    backdrop-filter: blur(13px);
    width: 180px;
    height: 50px;
  }

  .accept {
    border: 1px solid #f11515;
    background-color: #fff;
    color: #f11515;
    font-size: 16px;
    font-weight: 600;
  }

  .reject {
    background: #0ea5e9;
    border: 1px solid #0ea5e9;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }
}