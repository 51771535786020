.featuredNftDetailComp {
  padding: 100px 0;
  .title {
    color: #000;
    font-size: 48px;
    font-weight: 700;
  }
  .tab {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background-color: transparent;
  }
  .activeTab {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    border: none;
    padding: 14px 24px;
    font-weight: 600;
  }
}
