body {
  font-size: 16px;
  line-height: 1.5;
  background-color: #f5f7fa;
  font-family: "Inter", sans-serif;
}
html,
body {
  scroll-behavior: smooth;
}
* {
  box-sizing: border-box;
}
#root {
  /* height: 100%; */
  /* overflow: auto; */
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}
p {
  margin: 0;
}
.text-white {
  color: #fff !important;
}
button:hover {
  opacity: 0.8;
}
.primaryBtn {
  border-radius: 4px !important;
  background: #8066ff !important;
  border: 1px solid #8066ff !important;
  padding: 10px 40px !important;
  color: #fff;
  height: 48px;
}
.primaryBtn:hover {
  opacity: 0.9;
}
.filterBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  border-radius: 5.124px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  height: 48px;
}
.filterBtn:hover {
  background-color: var(--body);
}
.filterTxt {
  color: #a9a9a9;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
}
.ant-drawer-header {
  border-bottom: none;
  align-items: center;
}
.anticon .anticon-close svg {
  color: #000;
}
.ant-drawer-title {
  color: var(--txt);
  font-family: "Inter";
  font-size: 36px;
  font-weight: 600;
  padding: 10px 0;
}
.ant-drawer-close {
  position: absolute;
  right: 0;
  font-size: 25px;
}
/* .ant-collapse {
  margin-bottom: 20px;
  background-color: transparent;
  border: 0;
}
.ant-collapse-header-text {
  color: var(--txt);
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
}
.ant-collapse-expand-icon span {
  font-size: 16px !important;
  position: absolute;
  right: 0;
  bottom: 8px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-content {
  border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  background-color: var(--body);
} */

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}
.anticon.anticon-close,
.anticon.anticon-right.ant-collapse-arrow {
  color: var(--txt);
}
#myDateInput::-webkit-calendar-picker-indicator {
  background-color: #f2f2f2;
  padding: 2px;
  border-radius: 50%;
}
.selectDropdown {
  border: none;
  color: #71717a;
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter" !important;
  outline: 0;
  position: relative;
  width: 110px !important;
  background-color: transparent;
}
.disableBtn {
  background: #7b7b7b;
  border: 1px solid #7b7b7b;
  border-radius: 4px;
  padding: 10px 40px;
  color: #fff;
  height: 48px;
  outline: 0;
}
.disableBtn:hover {
  opacity: 0.9;
}
.blank-data {
  color: var(--txt);
  padding-left: 40px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  transition:
    background-color 5000s ease-in-out 0s,
    color 0.3s ease-in-out;
  background-color: transparent;
  -webkit-text-fill-color: var(--txt);
}
@media (max-width: 767px) {
  .filterBtn {
    padding: 5px 10px;
    height: 40px;
  }
  .filterBtn img {
    height: 22px;
  }
  .filterTxt {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .ant-drawer-content-wrapper {
    width: 330px !important;
  }
  .primaryBtn {
    padding: 0;
  }
}
.ant-dropdown-menu {
  padding: 10px 0;
  border-radius: 12px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.18);
  border: 0.5px solid #fff;
  background-color: #fff;
}
.ant-dropdown-menu-item {
  color: #4a5568;
  font-weight: 500;
}
.ant-dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: transparent;
  transition: background-color 0.3s;
  z-index: 100;
}

.navbar.colorChange {
  background-color: #007bff; /* Change to your desired background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff; /* Adjust text color for contrast */
}
.bgLight {
  background-color: #f5f7fa;
  /* height: 100vh; */
}
.cursor_pointer {
  cursor: pointer;
}
/* Override the background color for the Slick slider */
.slick-slider {
  background-color: transparent !important; /* Set background color to transparent */
  box-shadow: none !important;
}

/* Override the background color for the individual slides if needed */
.slick-slide {
  background-color: transparent !important; /* Set background color to transparent */
  box-shadow: none !important;
}
/* Remove box shadow from the Slick slider */
.slick-slider {
  box-shadow: none !important; /* Remove the box shadow */
}
.searchOuter {
  border-radius: 12px;
  border: 1px solid rgba(18, 18, 18, 0.12);
  width: 100%;
  padding: 12px;
}
.searchOuter input {
  border: none;
  outline: 0;
  background-color: transparent;
}
.searchOuter input:focus {
  box-shadow: none;
}
.searchOuter input::placeholder {
  color: #121212;
  opacity: 0.5;
  font-weight: 600;
}
.main_heading {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 10px;
}

.filters_main_heading {
  color: #000;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.radius {
  border-radius: 10px;
}
@media screen and (max-width: 576px) {
  .main_heading {
    font-size: 22px;
  }
}
video {
  object-fit: cover;
  border-radius: 10px;
}
@media screen and (max-width: 1199px) {
  video {
    width: 100%;
    height: auto;
  }
}
.ant-checkbox-inner {
  border: 2px solid #000;
}
.collectionSelect
  > div
  > div
  > div
  [id^="react-select-"][class*="placeholder"] {
  font-weight: 600;
  color: #2e2e2e;
}
/* .collectionSelect > div > div {
  border-radius: 10px;
} */
.collectionSelect > div > div > div {
  height: 86px !important;
}
.collectionSelect > div > div {
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.collectionSelect .ant-select {
  height: 86px !important;
  border-radius: 12px !important;
}
.collectionSelect .ant-select .ant-select-selector {
  height: 86px !important;
  border: 0 !important;
  padding: 0 10px !important;
  outline: 0 !important;
}
.collectionSelect .ant-select .ant-select-selector:focus,
.collectionSelect .ant-select .ant-select-selector:focus-visible {
  box-shadow: 0 !important;
  outline: 0 !important;
}
.collectionSelect .ant-select-selection-placeholder,
.collectionSelect .ant-select-selection-item {
  display: flex !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none;
}
.custom-collection-dropdown {
  left: 585px !important;
}
.notification .ant-dropdown-menu-item:first-child:hover {
  background-color: transparent;
  cursor: auto;
}
.notification .ant-dropdown-menu-item:first-child {
  border-bottom: 0;
}
.notification .ant-dropdown-menu-item {
  border-bottom: 1px solid #d1cbcb;
}
