.createNft {
  background-color: #f5f7fa;
  padding: 120px 0 70px;
  @media screen and (max-width: 575px) {
    padding-top: 80px;
  }
  .title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .desc {
    color: #777e90;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .uploadCardText {
    color: #777e90;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
  .nftCard {
    width: 312px;
    border-radius: 12px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    @media screen and (max-width: 1199px) {
      width: auto;
    }
    img,
    video {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: contain;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 250px;
      }
    }
    // video {
    //   height: 100%;
    //   width: 100%;
    //   border-top-left-radius: 12px;
    //   border-top-right-radius: 12px;
    // }
    .actionBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(8px);
      padding: 6px 10px 3px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        opacity: 0.85;
      }
    }
    .previewimg {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: contain;
      background-color: #e6e6e6;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 200px;
      }
    }

    .cardInfo {
      padding: 16px;
      .priceBorder {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px 10px;
      }
    }
    .price {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
    .previewTxt {
      background-color: #e6e6e6;
      height: 20px;
      width: 150px;
      display: block;
      border-radius: 10px;
    }
    .subTxt {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .form {
    .label {
      color: #2e2e2e;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    input {
      border-radius: 6px;
      border: 1px solid #ccc;
      height: 52px;
      padding: 12px;
      width: 100%;
      background-color: transparent;
      outline: 0;
    }
    ::placeholder {
      color: #17181a;
      font-size: 16px;
      font-weight: 400;
      opacity: 0.3;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /* Disable scrolling behavior */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    textarea {
      border-radius: 6px;
      border: 1px solid #ccc;
      padding: 12px;
      width: 100%;
      background-color: transparent;
      outline: 0;
    }
    .select {
      border-radius: 12px;
      border: 1px solid #ccc;
      height: 52px;
      // padding: 12px;
      width: 100%;
      background-color: transparent;
      outline: 0;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding-bottom: 10px;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    .btnOuter {
      display: flex;
      justify-content: flex-end;
      .blueBtn {
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }
    }
  }

  :global {
    .container {
      @media screen and (max-width: 575px) {
        max-width: 90%;
      }
      @media screen and (max-width: 400px) {
        max-width: 100%;
        padding: 0 30px;
      }
    }
    
  }
}
.disabeledBtn {
  background: #9e9e9e !important;
}
.blueBtn {
  border-radius: 10px;
  background: #0ea5e9;
  backdrop-filter: blur(13px);
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 12px 30px;
  margin-top: 10px;
  border: none;
  &:hover {
    opacity: 0.8;
    color: #fff;
  }
}
.modal {
  h3 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3px;
  }
  p {
    color: #777e90;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
}
.uploadTxt {
  color: #0ea5e9;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.uploadInput {
  position: absolute;
  top: 10px;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
  @media screen and (max-width: 575px) {
    width: 80%;
  }
}
.createNftdiv {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px;
  p {
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 600;
  }
  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
.collectionPlaceholder {
  display: flex;
  align-items: center;
  gap: 10px;
  .icon {
    background-color: #E7E7E7;
    height: 64px;
    width: 64px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
     font-size: 20px;
     color: #787878;
    }
  }
}
.optionImage {
  height: 64px;
  width: 64px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 12px;
}
.hashTag {
}
:global {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .ant-modal-body {
    padding: 40px;
  }
  .css-13cymwt-control {
    background-color: transparent !important;
  }
  .css-t3ipsp-control {
    border-color: #ccc !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
