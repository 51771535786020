.uploader {
  .bottomTxt {
    color: #17181a;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 5px;
  }
  :global {
    .ant-upload.ant-upload-drag {
      border-radius: 10px;
      border: 1px dashed #bdbdbd;
      background-color: transparent;
      height: 200px;
    }
    .ant-upload-text {
      color: #17181a;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #17181a;
        font-weight: 700;
        text-decoration-line: underline;
      }
    }
    // .ant-upload-span {
    //   padding: 10px 0;
    //   border-radius: 10px;
    //   border: 1px solid #eee;
    //   background: #f5f5f5;
    //   height: 50px;
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }
    // .ant-upload-list-text-container {
    //     margin-bottom: 40px;
    // }
  }
}
