.heroSection {
  padding: 90px 0 50px 0;
  background: linear-gradient(180deg, #0EA5E9, #56C1F1, #FFFF);
 @media screen and (max-width: 1199px) {
  padding-top: 120px;
 }
 @media screen and (max-width: 991px) {
  padding-top: 80px;
 }
  .sliding_container {
    overflow: hidden;
    margin-bottom: 10px;
    .sliding_txt {
      display: inline-block;
      white-space: nowrap;
      animation: slide 45s linear infinite;
      padding: 0 5px;
      color: #fff;
      word-spacing: 2px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.5),
        rgba(159, 159, 159, 0.3)
      );
      border-radius: 2px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
  @keyframes slide {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .inputOuter {
    background: rgba(255, 255, 255, 0.18);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    input {
      width: 100%;
      background: transparent;
      border: none;
      color: #fff;
      outline: 0;
    }
  }
  ::placeholder {
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
  }
  :global {
    .ant-btn {
      border-radius: 10px;
      background: transparent;
      height: 47px;
      width: auto;
      border: none !important;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.18);
        backdrop-filter: blur(13px);
        border: 1px solid #fff;
        color: #fff;
      }
    }
    .slick-dots {
      bottom: -35px;
      z-index: 9;
    }
    .slick-dots li {
      margin: 0 3px !important;
      height: 10px;
      width: 10px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: transparent !important;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.8);
    }
    .slick-dots li button:before {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      opacity: 0.25;
      color: transparent !important;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(15px);
    }
  }
}
.sliderbg1 {
  padding: 10px 50px;
  background-color: #0EA5E9;
  border-radius: 20px;
  height: 350px;
  position: relative;
  @media screen and (max-width: 991px) {
    text-align: center;
    align-items: center;
    height: 350px;
    display: flex !important;
  justify-content: center;
  align-items: center;
  }
  @media screen and (max-width: 576px) {
    height: 350px;
    padding: 20px;
  }
}

.sliderHeading {
  color: #fff;
  text-shadow: 0px 4px 28px rgba(0, 0, 0, 0.3);
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 0;
  @media screen and (max-width: 1199px) {
    font-size: 40px;
  }
  @media screen and (max-width: 991px) {
    font-size: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
}
.sliderTxt {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0px 4px 28px rgba(0, 0, 0, 0.3);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.22px;
  max-width: 550px;
  font-size: 24px;
  @media screen and (max-width: 1199px) {
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: 14px;
    max-width: auto;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
}
.blurBtn {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.18);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 26px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border: none;
  transition:
    background 0.3s,
    transform 0.3s;
  backdrop-filter: blur(30px);
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
}
.actionbtn {
  margin-left: 555px;
  text-align: center;
  margin-top: 10px;
  position: absolute;
  z-index: 9;
  @media (max-width: 1399px) {
    margin-left: 470px;
  }
  @media (max-width: 1199px) {
    margin-left: 380px;
  }
  @media (max-width: 991px) {
    margin-left: 250px;
  }
  @media (max-width: 767px) {
    margin-left: 170px;
  }
  @media (max-width: 540px) {
    margin-left: 30%;
  }
  @media (max-width: 480px) {
    margin-left: 24%;
  }
  @media (max-width: 400px) {
    margin-left: 21%;
  }
  img {
    cursor: pointer;
    height: 30px;
    &:hover {
      opacity: 0.5;
    }
  }
}
.customSlider {
  width: 100%;
}

.allDropDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  width: auto;
  padding: 0 25px;
  @media screen and (max-width: 576px) {
    padding: 0 20px;
  }
}
.activeTab {
  background: rgba(255, 255, 255, 0.18) !important;
  backdrop-filter: blur(13px);
  color: #fff;
  padding: 0 25px;
  width: auto;
  @media screen and (max-width: 576px) {
    padding: 0 20px;
  }
}
.txtBlock {
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 58px;
    color: #ffff;
    font-weight: 900;
    line-height: normal;
    margin: 0;
    @media screen and (max-width: 1199px) {
      font-size: 36px;
    }
    @media screen and (max-width: 991px) {
      font-size: 26px;
    }
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 41px;
    color: #ffff;
    font-weight: 900;
    line-height: 50px;
    margin: 0;
    a {
      color: #daf1ff;
    }
    @media screen and (max-width: 1199px) {
      font-size: 26px;
      line-height: normal;
    }
    @media screen and (max-width: 991px) {
      font-size: 16px;
      line-height: normal;
    }
    @media screen and (max-width: 576px) {
      font-size: 14px;
    }
  }
  .pdTop {
    padding-top: 38px;
    @media screen and (max-width: 991px) {
      padding-top: 0;
    }
  }
  .date {
    color: #c2e5f9;
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
}
.whiteBtn {
  background-color: #fff;
  border: none;
  width: 250px;
  padding: 12px 10px;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 700;
  display: block;
  text-align: center;
  color: #000;
  position: absolute;
  left: 50px;
  bottom: 25px;
  z-index: 99;
  &:hover {
    color: #000;
    opacity: 0.9;
  }
  @media screen and (max-width: 991px) {
    left: 40%;
    width: 150px !important;
    bottom: 10px;
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    left: 5%;
    width: 90% !important;
    bottom: 10px;
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    margin: 0 auto;
    width: 150px;
    font-size: 10px;
    width: 100px;
  }
}
.txtSection {
  .circle {
    background-color: #fff;
    color: #0EA5E9;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    @media screen and (max-width: 991px) {
      margin: 0 auto 15px;
      height: 20px;
      width: 20px;
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 auto 5px;
    }
  }
  h3 {
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 5px 0;
    line-height: normal;
    @media screen and (max-width: 1399px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1199px) {
      font-size: 16px;
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
    @media screen and (max-width: 576px) {
      font-size: 10px;
    }
  }
  p {
    color: #cfecfb;
    @media screen and (max-width: 1399px) {
      font-size: 14px;
    }
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
    @media screen and (max-width: 576px) {
      font-size: 9px;
    }
  }
}
.sliderImg {
  height: 325px;
  @media screen and (max-width: 991px) {
    height: 150px;
    object-fit: contain;
  }
  @media screen and (max-width: 576px) {
    height: 150px;
    width: 100%;
  }
}