.notifyOuter {
  background-color: #f5f7fa;
  padding: 100px 0 0 0;
  .title {
    color: #000;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 10px;
  }
    .main {
      width: 100%;
      @media screen and (max-width: 991px) {
       padding-left: 0;
      }
    }
}
