.faq {
  .infotxt {
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 16px;
  }
  ul {
    li {
      margin-bottom: 10px;
    }
  }
  :global {
    .ant-collapse-header {
      border-bottom: 1px solid rgba(207, 219, 213, 0.6);
      border-top: 1px solid rgba(207, 219, 213, 0.6);
      padding: 10px 0;
    }
    .ant-collapse-header-text {
      color: #121212;
      font-size: 16px;
      font-weight: 600;
      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
    }
    .ant-collapse-expand-icon span {
      font-size: 16px !important;
      position: absolute;
      right: 0;
      top: 22px;
      @media screen and (max-width: 576px) {
       top: 17px;
      }
    }
    .ant-collapse-content-box {
      padding: 20px 10px 20px 10px !important;
    }
  }
}
