.createCollection {
  padding: 70px 0;
  @media screen and (max-width: 575px) {
    padding-top: 30px;
  }
  .title {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 5px;
    @media screen and (max-width: 575px) {
      font-size: 26px;
    }
  }
  .subtitle {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .desc {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }
  .label {
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .input {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #ccc;
    outline: 0;
    padding: 12px;
    background-color: transparent;
  }
  .uploadTxt {
    color: #0ea5e9;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  .uploadInput {
    position: absolute;
    top: 10px;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
    @media screen  and (max-width: 575px){
      width: 90%;
    }
  }
  .outer {
    border-radius: 10px;
    border: 0.5px solid #5a5a5a;
    padding: 12px;
    p {
      color: #2e2e2e;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1d1d1d;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    .btnOuter {
      display: flex;
      justify-content: flex-end;
      .blueBtn {
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }
    }
  }

  .txt {
    color: #777e90;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .nftCard {
    width: 312px;
    border-radius: 12px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    @media screen and (max-width: 1199px) {
      width: auto;
    }
    img {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: contain;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 250px;
      }
    }
    .actionBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(8px);
      padding: 6px 10px 3px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
    .previewimg {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: cover;
      background-color: #e6e6e6;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 200px;
      }
    }

    .cardInfo {
      padding: 16px;
      .priceBorder {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px 10px;
      }
    }
    .price {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
    .previewTxt {
      background-color: #e6e6e6;
      height: 20px;
      width: 150px;
      display: block;
      border-radius: 10px;
    }
    .subTxt {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.modalInner {
  img {
    height: 200px;
    width: 200px;
    object-fit: contain;
  }
  p {
    color: #000;
    font-size: 28px;
    font-weight: 700;
  }
}
.blueBtn {
  border-radius: 10px;
  background: #0ea5e9;
  backdrop-filter: blur(13px);
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 12px 30px;
  border: none;
  &:hover {
    opacity: 0.8;
    color: #fff;
  }
}
