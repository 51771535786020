.notFound {
    background-image: url(../../../../assets/images/section/404.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    h1 {
        font-weight: 800;
        margin-bottom: 10px;
        font-size: 40px;
    }
    p {
        font-size: 18px;
        font-weight: 500;
    }
}