.login {
  // background-image: url(../../assets/images/section/loginBg.jpg);
  background-image: url(../../assets/images/section/authBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  height: 100vh;
  @media screen and (max-width: 991px) {
    padding: 30px 0;
    // height: 100%;
  }
  @media screen and (max-width: 576px) {
    align-items: flex-start;
  }
  .title {
    color: #fff;
    text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.13);
    font-size: 60px;
    font-weight: 700;
    display: inline-block;
    @media screen and (max-width: 991px) {
      text-align: center;
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      font-size: 40px;
    }
  }
  .subTxt {
    color: #4e4e51;
    font-size: 14px;
    font-weight: 400;
  }
  .logo{
    img {
      height: 60px;
      // position: relative;
      // left: 15%;
      @media screen and (max-width: 991px) {
        display: block;
        margin: 20px auto;
      }
      @media screen and (max-width: 575px) {
       height: 40px;
      }
    }
  }
  .formOuter {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.14);
    padding: 30px;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 400px) {
      padding: 20px 15px;
    }
    .signUp {
      color: #0d0d0d;
      font-size: 24px;
      font-weight: 500;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
        text-underline-offset: 8px;
      }
    }
    .heading {
      color: #131516;
      font-size: 42px;
      font-weight: 600;
      margin: 0;
      @media screen and (max-width: 575px) {
        font-size: 30px;
      }
    }
    .desc {
      color: #0d0d0d;
      font-size: 20px;
      font-weight: 400;
      @media screen and (max-width: 575px) {
        font-size: 16spx;
      }
    }
    .desc2 {
      color: #0d0d0d;
      font-size: 16px;
      font-weight: 400;
    }
    .formFields {
      .inputOuter {
        border-radius: 16px;
        border: 1px solid rgba(13, 13, 13, 0.3);
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        input {
          width: 100%;
          border: none;
          outline: 0;
        }
      }
      .link {
        color: #0e66e9;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .blueBtn {
      border-radius: 16px;
      background: #0ea5e9;
      padding: 16px;
      width: 100%;
      border: none;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      &:hover {
        opacity: 0.9;
      }
    }
    .break {
      background: #fff;
      width: 50px;
      display: inline-block;
      position: relative;
      text-align: center;
    }
    .googleBtn {
      border-radius: 16px;
      border: 1px solid rgba(13, 13, 13, 0.3);
      height: 60px;
      background-color: #fff;
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      color: #0d0d0d;
      font-size: 18px;
      font-weight: 400;
      &:hover {
        opacity: 0.8;
      }
      .txt {
        color: #0d0d0d;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  ::placeholder {
    color: rgba(13, 13, 13, 0.3);
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
  }
}
.signup {
  background-image: url(../../assets/images/section/authBg.png);
}
.setpassword {
  background-image: url(../../assets/images/section/authBg.png);
}
.emailVerification{
  background-color: #0EA5E9;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  gap: 10px;
}