.profile {
  padding-bottom: 20px;
  .title {
    color: #000;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 10px;
    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }
  .label {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .input {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 12px;
    outline: 0;
    background-color: transparent;
    width: 100%;
  }
  ::placeholder {
    color: #17181a;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.3;
  }
  .txt {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
  }
  .bannerImg {
    border-radius: 20px;
    background: #ebebeb;
    height: 200px;
    width: 90%;
    position: relative;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      height: 150px;
    }
    img {
      border-radius: 20px;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .editIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    input {
      position: absolute;
      top: 10px;
      right: -150px;
      opacity: 0;
      cursor: pointer;
    }
    img {
      cursor: pointer;
    }
  }
  .edit2 {
    right: -10px;
  }
  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 14px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }
  .profileImg {
    width: 210px;
    height: 210px;
    border-radius: 100%;
    background: #ebebeb;
    position: relative;
    @media screen and (max-width: 991px) {
      width: 150px;
      height: 150px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .formOuter {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  :global {
    .esGow {
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
