.organizationCard {
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  background-color: #fff;
  width: 96%;
  margin-bottom: 20px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .mainImg {
    width: 100%;
    border-radius: 12px;
    height: 100px;
  }
  .userContainer {
    position: relative;
    display: flex;
    justify-content: center;
    top: -45px;
    .userImg {
      width: 70px;
      height: 70px;
      object-fit: contain;
      border-radius: 14px;
      border: 2px solid #d4ddea;
    }
    .icon {
      position: absolute;
      height: 35px;
      bottom: -7px;
      margin-left: 65px;
    }
  }
  .bottomInfo {
    margin-top: -30px;
  }
  .title {
    color: #131516;
    font-size: 22px;
    font-weight: 700;
    margin: 0;
  }
  .creator {
    color: #a0aec0;
    font-size: 16px;
    font-weight: 600;
  }
  .name {
    color: #029cf5;
    font-weight: 600;
  }
  .border_right {
    border-right: 1.053px solid #e2e8f0;
    padding-right: 30px;
  }
  .subtitle {
    color: #a0aec0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
  .value {
    color: #131516;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
}
