.formFields {
  .label {
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .input {
    border-radius: 6px;
    border: 1px solid #CCC;
    background-color: transparent;
    outline: 0;
    width: 100%;
    padding: 10px 12px;
    // @media screen and (max-width: 576px) {
    //   width: 90%;
    // }
  }

  .selectInput>div {
    // height: 46px !important;
    padding: 2px 0px !important;
  }

  .txt {
    color: #8d8d8d;
    font-size: 14px;
  }

}

.subFormTxt {
  font-size: 16px;
  font-weight: 600;

  span {
    color: #1890ff;
    font-weight: 500;
    cursor: pointer;
  }
}