.invite {
  padding-bottom: 20px;
  .title {
    color: #000;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 5px;
    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }
  .btn {
    background-color: transparent;
    border: 1px solid green;
    border-radius: 8px;
    color: green;
    padding: 3px 8px;
    background-color: #f6ffed;
  }
  .delete {
    border: 1px solid red;
    color: red;
    background-color: #fff1f0;
  }
  .inputOuter {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    width: 50%;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    input {
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 5px 10px;
      width: 100%;
    }
  }
  .addBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    font-weight: 500;
    backdrop-filter: blur(13px);
    padding: 14px 22px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background-color: #0ea5e9;
    @media screen and (max-width: 575px) {
      width: 100%;
      justify-content: center;
    }
  }

  :global {
    .ant-table {
      border: 1px solid #ccc;
      border-radius: 10px;
    }
    .ant-table-thead > tr > th {
      font-weight: 600;
      font-size: 16px;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }
  }
}
.addContent {
  .label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 8px 10px;
    border-radius: 5px;
    outline: 0;
  }
  .sendbtn {
    border-radius: 10px;
    padding: 10px 22px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 50%;
    background-color: #0ea5e9;
    text-align: center;
  }
  .rejectbtn {
    background: rgba(0, 0, 0, 0.1);
    color: #000;
    border-radius: 10px;
    padding: 10px 22px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    width: 50%;
  }
  .reject {
    border: 1px solid red;
    color: red;
    background-color: #fff1f0;
  }
}
.txt_area {
    width: 100%;
    border: 1px solid #ccc;
    padding: 8px 10px;
    border-radius: 5px;
    outline: 0;
}