.mainOuter {
    padding: 50px 0;
    .title {
      color: #000;
      font-size: 45px;
      font-weight: 700;
      @media screen and (max-width: 576px) {
        font-size: 30px;
      }
    }
    .content {
      padding-top: 20px;
      .subTitle {
        color: #272727;
        font-size: 26px; 
        font-weight: 700;
        margin: 0;
      }
      .desc {
        color: #909299;
        font-size: 16px;
        font-weight: 500;
      }
     
      .blueBtn {
        border-radius: 10px;
        background: #0ea5e9;
        padding: 14px 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border: none;
        &:hover {
          opacity: 0.9;
        }
      }
      .back {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }
  