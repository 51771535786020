.connectModal {
  .connectTxt {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
  .innerContent {
    .desc {
      text-align: center;
      color: #353535;
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(13, 13, 13, 0.12);
    }
  }
  .connectBtn {
    border: 0;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px 5px;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      border-radius: 5px;
    }

    .walletName {
      color: #0d0d0d;
      font-size: 16px;
      font-weight: 600;
    }
    .connected {
      color: green;
    }
    img {
      height: 2rem;
    }
  }
  :global {
    .ant-modal-header {
      padding-top: 30px;
      border: 0;
    }
    .ant-modal-title {
      color: #000;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
    }
    .ant-modal-body {
      padding: 10px 40px;
    }
  }
}
.wallet {
  padding: 50px 0;
  .title {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }
  .desc {
    text-align: center;
    color: #353535;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(13, 13, 13, 0.12);
  }
  .inner {
    padding: 50px 100px 0;
    @media screen and (max-width: 991px) {
      padding: 50px 0 0;
    }
  }
  .info {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
  }
  .outer {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    background: #fff;
    &:hover {
      background-color: #f3f3f3;
    }
    &:hover .iconOuter img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
    .iconOuter {
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 15px;
    }
    .name {
      font-weight: 600;
      font-size: 20px;
    }
    
  }
  .btnOuter {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    padding: 14px 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border: none;
    &:hover {
      opacity: 0.9;
    }
  }
}