.banned {
  background-image: url(../../../../assets/images/section/banned.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  .outer {
    height: 100vh;
    @media screen and (max-width: 767px) {
      height: 100%;
      padding-top: 50px;
      text-align: center;
    }
  }
  h1 {
    font-weight: 800;
    margin-bottom: 10px;
    font-size: 35px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
    a {
      color: #2f2fde;
    }
  }
}
