.setting {
  background-color: #f5f7fa;
  padding: 120px 0 80px 0;
  .outer {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    .sidebar {
      width: 250px;
      border-right: 1px solid #cccccc;
      position: fixed;
      height: 100vh;
      z-index: 9;
      @media screen and (max-width: 991px) {
        width: 100%;
        border-right: 0;
        position: inherit;
        height: auto;
      }
      .title {
        color: #2e2e2e;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .navLink {
        padding: 5px 12px;
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
        margin-right: 20px;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          color: #6f6f6f;
          font-size: 16px;
          font-weight: 500;
        }
        .icon {
          font-size: 20px;
          color: grey;
        }
        .security {
          font-size: 25px;
          color: grey;
        }
        // &:hover {
        //   background: #f0f0f0;
        // }
      }
      .navActive {
        background: #f0f0f0;
      }
      .friendInvite {
        padding-left: 16px;
        span {
          padding-left: 2px;
        }
      }
    }
    .tabsOuter {
      @media screen and (max-width: 991px) {
        display: flex;
        overflow-x: auto;
      }
    }
    .main {
      padding-left: 300px;
      width: 100%;
      @media screen and (max-width: 991px) {
       padding-left: 0;
      }
    }
  }
}
