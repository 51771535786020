.profile {
  padding-bottom: 20px;

  .title {
    color: #000;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 5px;

    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }

  .label {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .input {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 12px;
    outline: 0;
    background-color: transparent;
    width: 100%;
  }

  .selectInput>div {
    padding: 3px 0px !important;

  }

  ::placeholder {
    color: #17181a;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.3;
  }

  .txt {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
  }

  .bannerImg {
    border-radius: 20px;
    background: #ebebeb;
    height: 250px;
    width: 90%;
    position: relative;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      height: 150px;
    }

    img {
      border-radius: 20px;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .editIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;

    input {
      position: absolute;
      top: 10px;
      right: -150px;
      opacity: 0;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }

  .edit2 {
    right: -10px;
  }

  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 14px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }

  .blueBtn:disabled {
    cursor: not-allowed;
    opacity: 0.5; // Optional: reduces the opacity to make it visually disabled
  }


  .profileImg {
    width: 170px;
    height: 170px;
    border-radius: 100%;
    background: #ebebeb;
    position: relative;

    @media screen and (max-width: 991px) {
      width: 120px;
      height: 120px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: fill;
    }
  }

  .formOuter {
    width: 50%;

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  .avatar {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    padding: 20px;
    margin-top: 30px;
    width: 100%;
    background: #fff;
    width: 500px;

    @media screen and (max-width: 1199px) {
      padding: 10px;
    }

    @media screen and (max-width: 576px) {
      width: 340px;
      grid-template-columns: repeat(4, 1fr);
    }

    cursor: pointer;

    img {
      height: 46px;
      width: 46px;
    }

    @media screen and (max-width: 1199px) {
      grid-gap: 10px;
    }
  }
}