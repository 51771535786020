.footerOuter {
  background: #f5f7fa;
  margin-top: 0;

  .joinSection {
    display: none;
  }

  .joinNow {
    // background-image: url("../../../assets/images/section/joinBg.svg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    background: #34baf9;
    padding: 80px 0;
    display: block;
    margin: 0 auto;
    border-radius: 30px;
    color: #fff;
    position: relative;
    top: 150px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;

    @media screen and (max-width: 1399px) {
      top: 180px;
      padding: 40px 10px;
    }

    @media screen and (max-width: 1199px) {
      top: 140px;
    }

    .infoCard {
      h3 {
        color: #fff;
        text-align: center;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 18px;
      }

      p {
        color: #fff;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        margin: 0;
      }

    }

    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 30px;
      max-width: 600px;

      @media screen and (max-width: 1399px) {
        font-size: 16px;
      }
    }

    .joinBtn {
      display: block;
      margin: 0 auto;
      border-radius: 10px;
      background: #fff;
      color: #000;
      padding: 10px 60px;
      border: none;
      font-size: 20px;
      font-weight: 500;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer {
  padding: 250px 0 50px 0;
  background: #0EA5E9;

  // background: linear-gradient(to right, #0b0b0b 50%, #832a58 85%, #493c5a 100%),
  //   linear-gradient(to bottom, #493c5a 0%, #493c5a 100%);
  // background-image: url("../../../assets/images/section/footer.svg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // position: relative;
  @media screen and (max-width: 1399px) {
    padding-top: 200px;
  }

  .logo {
    img {
      height: 35px;
    }
  }

  .toptxt {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    padding-right: 60px;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }

  .inputOuter {
    border-radius: 10px;
    border: 0.982px solid #fff;
    background: #1b1b1b;
    padding: 15px;
    width: 80%;

    ::placeholder {
      color: #777e90;
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: 0;
      color: #fff;
    }

    img {
      cursor: pointer;
    }
  }

  .menuHeading {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .menuLink {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;

    &:hover {
      color: #fff;
    }
  }

  .privacyOuter {
    padding: 35px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    margin: 35px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      padding: 3px 0;

      &:hover {
        color: #fff;
      }
    }
  }

  .bottomTxt {
    font-size: 15px;
    font-weight: 500;
    color: #fff;

    a {
      font-weight: 600;
      color: #233cc9;
    }
  }

  .twitterIcon {
    svg {
      path {
        fill: #383a40;
      }
    }
  }
}

.loginFooter {
  padding: 80px 0 40px 0;
  margin-top: 60px;
}