.light-theme{
    --body: #F3F6FC;
    --activeTxt: #8066FF;
    --txt: #000;
    --txt-light: #2C3131;
    --bgBase: #8066FF;
    --tabBg: #EBEEFD;
    --border-color: #A9A9A9;
    --cardBg: #fff;
    --grey-500: #5C5C5C;
    --simpleBg: #fff;
    --bg-light: #f5f5f5;
    --tabTxt: #8066FF;
    --bg-400: #f5f5f5;
    --bg-300: #fff;
    --light-grey: #B4B3BC;
    --bg-hover: #f3f3f3;
    --content-wrap: #f5f5f5;
    --border-light: #355270;
    --txt-light: #5D6679;
    --selected-bg:  #aebbfa;
}
.dark-theme{
    --body: #181818;
    --activeTxt: #8066FF;
    --txt: #fff;
    --bgBase: #03b1f4;
    --tabBg: #8066FF;
    --border-color: #fff;
    --cardBg: #0D0D0D;
    --grey-500: #949191;
    --simpleBg: #000;
    --bg-light: #171717;
    --bg-hover: #171717;
    --tabTxt: #fff;
    --bg-400: #171717;
    --bg-300: #000;
    --txt-light: #fff;
    --light-grey: #fff;
    --bg-400: #0D0D0D;
    --content-wrap: #171717;
    --border-light: #fff;
    --txt-light: #fff;
    --selected-bg:  #8066FF;
}
