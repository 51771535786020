.notification {
  .title {
    color: #000;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 10px;
    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }
  .checkboxOuter {
    border-radius: 10px;
    border: 1px solid #ccc;
    .label {
      color: #2e2e2e;
      font-size: 17px;
      font-weight: 600;
      margin: 0 0 5px 0;
    }
    .desc {
      color: #2e2e2e;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 14px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  .formOuter {
    width: 75%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  :global {
    .ant-checkbox-wrapper {
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding: 20px;
    }
    .ant-checkbox-wrapper:last-child {
      border-bottom: 0;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
