.userTabs {
  .tabsOuter {
    overflow-x: auto;

    @media screen and (max-width: 991px) {
      overflow-x: auto;
    }
  }

  .gap {
    gap: 38px;

    @media screen and (max-width: 576px) {
      gap: 5px;
    }
  }

  .tab {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background-color: transparent;

    @media screen and (max-width: 1399px) {
      width: 180px;
    }

    @media screen and (max-width: 991px) {
      font-size: 14px;
      width: 145px;
    }
  }

  .activeTab {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    border: none;
    padding: 14px 24px;
    font-weight: 600;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 576px) {
      min-width: 120px;
    }
  }

  .itemsOuter {
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }

  .searchOuter {
    border-radius: 12px;
    border: 1px solid rgba(18, 18, 18, 0.12);
    width: 100%;
    padding: 12px;

    input {
      border: none;
      outline: 0;
      background-color: transparent;

      &:focus {
        box-shadow: none;
      }

      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
    }

    ::placeholder {
      color: #121212;
      opacity: 0.5;
      font-weight: 600;
    }
  }

  .causeOuter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 21px;
    border: 1px solid #e9e9e9;
    background: #fff;
    box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.05);
    padding: 30px 10px 10px;
    gap: 20px;
    height: 270px;

    &:hover {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    &:hover .img {
      transform: scale(1.15);
      transition: 0.3s ease-in-out;
    }

    .img {
      height: 140px;
      border-radius: 10px;
    }

    .txt {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      word-break: break-word;
    }
  }

  .rightSection {
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      gap: 20px;
    }
    .rightSectionBtns {
      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .topbtns {
      width: auto;
    }

    .topsale {
      width: 186px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      svg {
        font-size: 25px;
      }
    }

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;

      .outlineBtn {
        width: 100%;
      }
    }
  }

  .interestBtn {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  .collectionBtn {
    @media screen and (max-width: 575px) {
      min-width: 100%;
    }

    .outlineBtn {
      width: 170px;

      @media screen and (max-width: 767px) {
        width: 150px;
      }

      @media screen and (max-width: 575px) {
        min-width: 100%;
      }
    }
  }

  .outlineBtn {
    border-radius: 10px;
    border: 1px solid #0ea5e9;
    backdrop-filter: blur(13px);
    color: #0ea5e9;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    padding: 11px 10px;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }

  .w_more {
    width: 170px;

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  .outer {
    .title {
      color: #000;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;

      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }
  }

  .vertfication {
    border-radius: 14px;
    border: 1px solid #afafaf;
    padding: 20px;
    display: flex;
    gap: 10px;

    img {
      height: 20px;
      margin-top: 4px;
    }

    p {
      color: #0e0e0e;
      font-size: 18px;
      font-weight: 500;
    }

    span {
      color: #0e0e0e;
      font-size: 16px;
      font-weight: 500;
      opacity: 0.6;
    }
  }

  button {
    background-color: transparent;
    margin: 0 2px;
    // padding: 0 2px;
    flex-shrink: 0;
  }

  .projOuter {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
    // &:hover {
    //   border-radius: 10px;
    //   background: #f5f5f5;
    // }
    .title {
      color: #000;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;

      @media screen and (max-width: 575px) {
        font-size: 20px;
      }
    }

    .img {
      min-height: 55px;
      max-width: 55px;
      height: 55px;
      width: 55px;
      border-radius: 100%;
      border: 1px solid #ccc;
      position: relative;
    }

    .check {
      right: -8px;
      top: 32px;
    }

    .name {
      color: #131516;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      text-align: left;
      word-break: break-word;
      a {
        word-break: break-word;
      }
    }

    .label {
      color: #a0aec0;
      font-size: 14px;
      font-weight: 500;
    }

    .value {
      color: #4a5568;
      font-size: 14px;
      font-weight: 500;
    }
  }

  :global {
    .ant-select {
      @media screen and (max-width: 575px) {
        width: 45% !important;
      }
    }

    .ant-select,
    .ant-select-selector {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      color: #121212;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 12px;
      border: 1px solid rgba(18, 18, 18, 0.12);
      background-color: transparent;
      color: #121212;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      height: 52px;

      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }
  }
}

.projModal {
  .modalInner {
    .formInput {
      margin-bottom: 30px;

      .label {
        color: #2e2e2e;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      input {
        border-radius: 6px;
        border: 1px solid #ccc;
        padding: 12px;
        background-color: transparent;
        width: 100%;
        outline: 0;
      }

      .uploadTxt {
        color: #0ea5e9;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 4px;
      }

      .uploadInput {
        position: absolute;
        top: 10px;
        opacity: 0;
        z-index: 99;
        cursor: pointer;
        width: 30%;
      }
    }

    .blueBtn {
      width: 100%;
      border-radius: 10px;
      background: #0ea5e9;
      backdrop-filter: blur(13px);
      padding: 14px 24px;
      border: 0;
      color: #fafafa;
      font-size: 16px;
      font-weight: 600;
    }
  }

  :global {
    .ant-modal-header {
      border-bottom: 0;
      padding-top: 30px;
    }

    .ant-modal-title {
      color: #000;
      font-size: 28px;
      font-weight: 700;
    }
  }
}

:global {
  .ant-picker {
    border-radius: 12px;
    height: 52px;
    background-color: transparent;
  }

  .ant-modal-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-select,
  .ant-select-selector {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    color: #121212;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
    border: 1px solid rgba(18, 18, 18, 0.12);
    background-color: transparent;
    color: #121212;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    height: 52px;
  }
}

.topcontainer {
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px 0;

  span {
    font-size: 14px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 22px;
  }

  p {
    color: #0ea5e9;
    font-weight: 600;
  }

  .red {
    color: red;
  }
}

.listingOuter {
  height: 370px;
  overflow-y: auto;
}

.listing {
  padding: 5px;
  margin-bottom: 5px;
  padding: 10px;

  .viewBtn {
    display: none;
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 10px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
    width: 80px;
  }

  &:hover {
    background-color: #f2f2f2;
    border-radius: 12px;
  }

  &:hover .rightSection {
    display: none;
  }

  &:hover .viewBtn {
    display: block;
  }

  .userImg {
    height: 40px;
    width: 40px;
    border-radius: 12px;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .time {
    color: #0ea5e9;
    font-size: 12px;
  }

  .txt {
    color: #888;
  }
}

.listing2 {
  cursor: pointer;

  &:hover .rightSection {
    display: block;
  }
}

.blueBtn {
  width: 100%;
  border-radius: 10px;
  background: #0ea5e9 !important;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.approvedBtn {
  width: 100%;
  border-radius: 10px;
  background: #01a301 !important;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.disapprovedBtn {
  width: 100%;
  border-radius: 10px;
  background: #f93030 !important;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.pendingBtn {
  width: 100%;
  border-radius: 10px;
  background: #ff7e4f !important;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.activeButton {
  opacity: 0.6;
  /* Adjust the opacity value as needed */
}

.redBtn {
  width: 100%;
  border-radius: 10px;
  background: #f25a67 !important;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.btnDiv {
  justify-content: space-around;
  display: flex;

  .myblueBtn {
    width: 50%;
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    padding: 14px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }

  .myredBtn {
    width: 50%;
    border-radius: 10px;
    background: red;
    backdrop-filter: blur(13px);
    padding: 14px 24px;
    border: 0;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }
}

.singleBlueBtn {
  width: 100%;
  border-radius: 10px;
  background: #0ea5e9;
  backdrop-filter: blur(13px);
  padding: 14px 24px;
  border: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
}

.w_Btn {
  padding: 10px;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.mainDiv {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px 10px !important;
  background-color: #fff;
}

.noGraphData {
  background-image: url("../../assets/images/section/noData.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 500;
  }
}

.grandTotal {
  h4 {
    font-size: 20px;
    margin: 0;
  }
}

.actionIcons {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  padding: 7px 10px;
  border: 0;
  color: #fafafa;
  font-size: 14px;
  font-weight: 600;

  svg {
    font-size: 20px;
  }
}

.view {
  background-color: #0ea5e9 !important;
}

.edit {
  // background-color: #fe571a !important;
  background-color: #ff7e4f !important;
}

.approve {
  background-color: #01a301 !important;
}

.reject {
  background-color: #f93030 !important;
}

.statsModal {
  width: 900px !important;

  @media screen and (max-width: 991px) {
    width: auto !important;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.datelabel {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.metaInfoInRed {
  border-radius: 10px;
  // background: rgba(242, 9, 5, 0.11);
  padding: 6px 16px;
  color: red;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  font-style: italic;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;

  svg {
    margin-top: -3px;
  }
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 0 10px;

  &:hover {
    border-radius: 10px;
    background: #f5f5f5;
  }

  &:hover .column .item {
    border-bottom: 0;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;

    .item {
      margin: 5px 0;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      gap: 30px;
      border-bottom: 1px solid rgba(192, 204, 218, 0.3);

      .number {
        color: #4a5568;
        font-size: 20px;
        font-weight: 700;
      }

      .userImg {
        height: 55px;
        width: 55px;
        border-radius: 100%;
        object-fit: cover;
      }

      .varified {
        position: absolute;
        bottom: 0;
        right: -10px;
      }

      .title {
        color: #131516;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }

      .subTxt {
        color: #a0aec0;
        font-size: 12px;
        font-weight: 500;

        @media screen and (max-width: 1199px) {
          font-size: 11px;
        }
      }

      .price {
        color: #4a5568;
        font-size: 12px;
      }

      .amount {
        color: #029cf5;
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
      }

      .polygonTxt {
        color: #4a5568;
        font-size: 12px;
        font-weight: 500;
        padding: 0 24px;
      }
    }
  }

  .friendImg {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    object-fit: cover;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid #ccc;
  }

  .plusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    /* Adjust size as needed */
    height: 26px;
    /* Adjust size as needed */
    background-color: #abb0b5;
    /* Adjust background color as needed */
    color: #fff;
    /* Adjust text color as needed */
    border-radius: 50%;
    font-size: 12px;
    /* Adjust font size as needed */
    border: 1px solid #ccc;
  }

  /* Add this to your CSS file */
  .pagination {
    display: flex;
    /* Use flexbox for horizontal layout */
    justify-content: center;
    /* Center the pagination */
    align-items: center;
    /* Center vertically */
    list-style: none;
    /* Remove bullet points */
    padding: 0;
    /* Remove default padding */
    margin: 20px 0;
    /* Add some margin */
  }

  .pagination-button,
  .pagination-page {
    margin: 0 5px;
    /* Space between buttons */
    padding: 10px 15px;
    /* Padding for buttons */
    border: 1px solid #007bff;
    /* Border color */
    border-radius: 5px;
    /* Rounded corners */
    color: #007bff;
    /* Text color */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition:
      background-color 0.3s,
      color 0.3s;
    /* Smooth transition */
  }

  .pagination-button:hover,
  .pagination-page:hover {
    background-color: #007bff;
    /* Background color on hover */
    color: white;
    /* Text color on hover */
  }

  .active {
    background-color: #007bff;
    /* Active page background */
    color: white;
    /* Active page text color */
    pointer-events: none;
    /* Prevent interaction with the active button */
  }
}
.agreementBtn:hover {
  color: white;
  opacity: 0.8;
}
.actionBtns {
  @media screen and (max-width: 1199px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    width: 100%;
  }
}
