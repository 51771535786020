.friendSuggestion {
  padding: 80px 0 0 0;
  background-color: #f5f7fa !important;
  .heading {
    color: #131516;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  .seeAllBtn {
    border-radius: 8px;
    border: 0.983px solid #029cf5;
    box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
    padding: 5px 10px;
    color: #029cf5;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      text-align: center;
    }
  }
  .outer {
    border-radius: 10px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    width: 240px;
    background-color: #fff;
    @media screen and (max-width: 1399px) {
      width: 23.2%;
    }
    @media screen and (max-width: 1199px) {
      width: 31.5%;
    }
    @media screen and (max-width: 991px) {
      width: 48%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      .userImg {
        transform: scale(1.15);
        transition: 0.3s ease-in-out;
      }
    }
    .userImg {
      height: 60px;
      width: 60px;
      border-radius: 100%;
      object-fit: cover;
      object-position: top;
    }
    .username {
      color: #131516;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      text-align: center;
    }
    .txt {
      color: #a0aec0;
      font-size: 12px;
      font-weight: 500;
    }
    .friendImg {
      height: 26px;
      width: 26px;
      border-radius: 100%;
      object-fit: cover;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .txt2 {
      color: #4a5568;
      font-size: 12px;
      font-weight: 500;
    }
    .outlineBtn {
      border-radius: 10px;
      border: 1px solid #029cf5;
      box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
      width: 100%;
      background-color: #fff;
      padding: 10px 0;
      color: #029cf5;
      font-size: 18px;
      font-weight: 700;
      &:hover {
        background-color: #029cf5;
        color: #fff;
      }
    }
  }
  .plusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px; /* Adjust size as needed */
    height: 28px; /* Adjust size as needed */
    background-color: #abb0b5; /* Adjust background color as needed */
    color: #fff; /* Adjust text color as needed */
    border-radius: 50%;
    font-size: 14px; /* Adjust font size as needed */
  }
}
