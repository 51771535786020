.friendsDetailsComp {
  padding: 100px 0 0 0;
  background-color: #f5f7fa;

  @media screen and (max-width: 575px) {
    padding-bottom: 0;
  }

  .title {
    color: #000;
    font-size: 48px;
    font-weight: 700;

    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }

  .friendsTabs {
    @media screen and (max-width: 991px) {
      justify-content: flex-start !important;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .subtitle {
    color: #000;
    font-size: 28px;
    font-weight: 700;
  }

  .tab {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    border: none;
    background-color: transparent;
  }

  .activeTab {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13px);
    border: none;
    padding: 14px 24px;
    font-weight: 600;
  }

  .searchOuter {
    height: 60px;

    img {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .columnContainer {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;

      .item {
        margin: 5px 0;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        gap: 30px;
        border-bottom: 1px solid rgba(192, 204, 218, 0.3);

        @media screen and (max-width: 575px) {
          flex-direction: column;
        }

        .number {
          color: #4a5568;
          font-size: 20px;
          font-weight: 700;
        }

        .userImg {
          height: 55px;
          width: 55px;
          border-radius: 100%;
        }

        .varified {
          position: absolute;
          bottom: 0;
          right: -10px;
        }

        .friendImg {
          height: 26px;
          width: 26px;
          border-radius: 100%;
          object-fit: cover;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        .plusIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          /* Adjust size as needed */
          height: 28px;
          /* Adjust size as needed */
          background-color: #abb0b5;
          /* Adjust background color as needed */
          color: #fff;
          /* Adjust text color as needed */
          border-radius: 50%;
          font-size: 14px;
          /* Adjust font size as needed */
        }

        .title {
          color: #131516;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }

        .subTxt {
          color: #a0aec0;
          font-size: 12px;
          font-weight: 500;
        }

        .price {
          color: #4a5568;
          font-size: 12px;
        }

        .btn {
          border-radius: 10px;
          backdrop-filter: blur(13px);
          padding: 10px 22px;
          border: none;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          width: 100px;

        }

        .accept {
          background: #0ea5e9;

          @media screen and (max-width: 575px) {
            width: 50%;
          }
        }

        .reject {
          background: rgba(0, 0, 0, 0.1);
          color: #000;

          @media screen and (max-width: 575px) {
            width: 50%;
          }
        }
      }
    }
  }
}

.modalContent {
  word-break: break-word;

  h1 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }

  p {
    color: #777e90;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .btn {
    border-radius: 10px;
    backdrop-filter: blur(13px);
    width: 180px;
    height: 50px;
  }

  .accept {
    border: 1px solid #f11515;
    background-color: #fff;
    color: #f11515;
    font-size: 16px;
    font-weight: 600;
  }

  .reject {
    background: #0ea5e9;
    border: 1px solid #0ea5e9;
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
  }
}

.search {
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(9.5px);
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;

  input {
    width: 100%;
    border: none;
    outline: 0;
    background-color: transparent;
  }

  ::placeholder {
    color: #000;
  }

  .blueBtn {
    border-radius: 10px;
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    padding: 14px 22px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 100px;
    background-color: #0ea5e9;
  }

  :global {

    .ant-select,
    .ant-select-selector {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      color: #121212;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.1);
      color: #121212;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      height: 52px;
    }
  }
}

.selectOuter {
  :global {

    .ant-select,
    .ant-select-selector {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      color: #121212;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.1);
      color: #121212;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      height: 52px;
    }
  }
}

.blueBtn {
  border-radius: 10px;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  padding: 14px 22px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-color: #0ea5e9;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.loadmore {
  position: relative;

  button {
    position: absolute;
    top: -13px;
    z-index: 999;
    background-color: #f5f7fa;
    border: 0;
    padding: 0 10px;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
  }
}