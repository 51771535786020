.mainOuter {
  padding: 50px 0;
  .title {
    color: #000;
    font-size: 45px;
    font-weight: 700;
    @media screen and (max-width: 576px) {
      font-size: 30px;
    }
  }
  .content {
    padding-top: 20px;
    .subTitle {
      color: #272727;
      font-size: 26px;
      font-weight: 700;
      margin: 0;
      @media screen and (max-width: 576px) {
        font-size: 20px;
      }
    }
    .desc {
      color: #909299;
      font-size: 16px;
      font-weight: 500;
    }
    .selection {
      border-radius: 10px;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      background-color: #fff;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
      &:hover .selectionImg {
        transform: scale(1.05);
        transition: 0.3s ease-in-out;
      }
      .selectionImg {
        height: 140px;
        object-fit: contain;
        border-radius: 10px;
        margin-top: 10px;
      }
      .subTxt {
        margin: 0;
        color: #191d23;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
    .blueBtn {
      border-radius: 10px;
      background: #0ea5e9;
      padding: 14px 40px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      border: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .check {
    display: none;
  }
  .selected .check {
    display: block;
  }
  .selected {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
