.causeModal {
    .modalContent {
        .causesList {
            display: flex;
            align-items: center;
            gap: 8px;
            .counter {
                background-color: #029cf5;
                height: 20px;
                width: 20px;
                color: #fff;
                text-align: center;
                border-radius: 100%;
            }
            a {
                font-weight: 600;
                color: #000;
                &:hover {
                  opacity: 0.7;
                }
            }
            
        }
        .cencelbtn {
            border-radius: 10px;
            border: 1px solid  rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(13px);
            font-size: 16px;
            font-weight: 600;
            opacity: 1;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.05);
            height: 40px;
            &:hover {
                opacity: 0.8;
            }
          }
    }
    :global {
      .ant-modal-title {
        color: #000;
        font-size: 28px;
        font-weight: 700;
      }
      .ant-modal-body {
        padding: 20px;
      }
      .ant-picker {
        border: 0;
      }
    }
  }
  