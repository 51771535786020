.cause {
  padding: 0 0 40px 0;
  background-color: #fff !important;
  &:hover {
    .slick-arrow {
      display: block;
    }
  }
  .top {
    // @media screen and (max-width: 991px) {
    //   flex-direction: column;
    //   gap: 10px;
    // }
  }
  .bottom {
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
  .heading {
    color: #131516;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  .searchBtn {
    cursor: pointer;
    border-radius: 10px;
    border: 0.983px solid #029cf5;
    box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
    padding: 10px 12px;
    display: flex;
    gap: 5px;
    input {
      color: #000;
      background-color: transparent;
      border: none;
      outline: 0;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      width: 300px;
    }
    ::placeholder {
      color: #029cf5;
      opacity: 0.4;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .seeAllBn {
    border-radius: 8px;
    border: 0.983px solid #029cf5;
    box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
    padding: 5px 10px;
    color: #029cf5;
    // color: #fff;
    font-size: 14px;
    // background-color: transparent;
    font-weight: 700;
    // text-decoration: underline;
    // text-underline-offset: 2px;
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 450px) {
      width: 100%;
      text-align: center;
    }
  }
  .causeSlider {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 18px;
    border: 1px solid #e9e9e9;
    background: #fff;
    box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.05);
    padding: 5px;
    gap: 5px;
    width: 190px !important;
    @media screen and (max-width: 991px) {
      width: 100% !important;
    }
    @media screen and (max-width: 767px) {
      width: 150px !important;
    }
    @media screen and (max-width: 576px) {
      width: 145px !important;
    }
    @media screen and (max-width: 480px) {
      width: 120px !important;
    }
    @media screen and (max-width: 400px) {
      width: 90px !important;
    }
    &:hover .img {
      transform: scale(1.15);
      transition: 0.3s ease-in-out;
    }
    .img {
      height: 140px;
      width: 140px;
      // border-radius: 10px;
      @media screen and (max-width: 576px) {
        height: 60px;
        width: 60px;
      }
    }
    .txt {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      word-break: break-word;
      width: 190px;
      padding: 0 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      @media screen and (max-width: 767px) {
        width: 150px !important;
      }
      @media screen and (max-width: 576px) {
        width: 145px !important;
        font-size: 14px;
      }
      @media screen and (max-width: 480px) {
        width: 120px !important;
      }
      @media screen and (max-width: 400px) {
        width: 90px !important;
      }
    }
  }
  :global {
    .slick-track {
      display: flex;
      gap: 20px;
      @media screen and (max-width: 576px) {
        gap: 5px;
      }
    }
  }

  :global {
    .slick-arrow {
      // border-radius: 8px;
      // background: rgba(0, 0, 0, 0.1);
      height: 100%;
      // padding: 0 20px;
      // display: none !important;
    }
    .slick-arrow:hover,
    .slick-arrow:focus {
      // background: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-prev:before {
      content: url(../../assets/images/icons/leftArrow.svg);
      position: relative;
      right: 10px;
    }
    .slick-next:before {
      content: url(../../assets/images/icons/arrow-right.svg);
      position: relative;
      right: 20px;
    }
    .slick-slider:hover .slick-arrow {
      display: block !important;
    }
    .slick-slide {
      @media screen and (max-width: 576px) {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
    .slick-arrow.slick-prev {
      position: absolute;
      z-index: 9;
      left: 5px !important;
      width: auto;
    }
    .slick-next {
      @media screen and (max-width: 576px) {
        right: 0 !important;
      }
    }
    .slick-prev {
      @media screen and (max-width: 576px) {
        left: 16px !important;
      }
    }
  }
}
