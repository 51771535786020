.leaderBoard {
  padding: 50px 0;
  background-color: #fff !important;
  margin-top: 80px;
  .title {
    color: #131516;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  .polygonOuter {
    border-radius: 7px;
    border: 1px solid #e2e8f0;
    background: #fff;
    padding: 0;
    span {
      color: #4a5568;
      font-size: 15px;
      font-weight: 600;
      background-color: #f5f7fa;
      padding: 9px 13px 10px;
      border-radius: 7px 0 0 7px;
      border-right: 1px solid #e2e8f0;
    }
    img {
      padding: 11px 13px;
    }
  }
  .actionBtn {
    position: absolute;
    right: 0;
    top: 50px;
    @media screen and (max-width: 576px) {
      left: 10px;
      top: 82px;
    }
    @media screen and (max-width: 400px) {
      left: 10px;
      top: 70px;
    }
  }
  .categoryDropdown {
    width: 240px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #e2e8f0 !important;
    background: #fff;
    color: #4a5568;
    font-size: 16px;
    font-weight: 600;
  }
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 44px !important;
    }
  }
}
:global {
  .ant-tabs-nav-list {
    border: 1px solid #e2e8f0;
    border-radius: 7px;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-tab {
    background-color: #fff;
    border-bottom: none;
    border-radius: 7px 7px 7px 7px;
    color: #4a5568;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #f5f7fa;
    border-bottom: none;
    border-radius: 7px 0 0 7px;
    border-right: 1px solid #e2e8f0;
    color: #029cf5;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
    border-right: none;
    border-radius: 0 7px 7px 0;
  }
  .ant-tabs.ant-tabs-top {
    width: 100%;
  }
}
.seeAllBtn {
  border-radius: 8px;
  border: 0.983px solid #029cf5;
  box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
  padding: 5px 10px;
  color: #029cf5;
  font-size: 14px;
  font-weight: 700;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    text-align: center;
  }
}
