.document {
  .mainOuter {
    padding: 50px 0;
    .title {
      color: #000;
      font-size: 45px;
      font-weight: 700;
    }
    .content {
      padding-top: 100px;
      .subTitle {
        color: #272727;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .email {
        width: 100%;
        background-color: transparent;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 10px;
        outline: 0;
      }
      .desc {
        color: #4e4e51;
        font-size: 16px;
        font-weight: 500;
      }
      .subTxt {
        color: #4e4e51;
        font-size: 15px;
        font-weight: 400;
      }
      .bottomTxt {
        color: #17181a;
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
        margin-top: 5px;
      }
      .blueBtn {
        border-radius: 10px;
        background: #0ea5e9;
        padding: 14px 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
        border: none;
        &:hover {
          opacity: 0.9;
        }
      }
      .back {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }

}
