.activity {
  background-color: #f5f7fa;
  padding: 80px 0 0 0;
  .heading {
    color: #131516;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }
  .seeAllBtn {
    border-radius: 8px;
    border: 0.983px solid #029cf5;
    box-shadow: 0px 8px 12px 0px rgba(30, 30, 30, 0.04);
    padding: 5px 10px;
    color: #029cf5;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      opacity: 0.7;
    }
    @media screen  and (max-width: 450px) {
      width: auto;
      text-align: center;
    }
  }
  :global {
    .slick-list {
      margin-left: -24px !important;
      @media screen and (max-width: 1199px) {
        margin-left: 0 !important;
      }
    }
    .slick-track {
      display: flex;
      // justify-content: space-between;
      gap: 30px;
      margin: 0;
      @media screen and (max-width: 991px) {
       gap: 40px;
      }
      @media screen and (max-width: 767px) {
        gap: 10px;
       }
      // width: 100% !important;
    }
    .slick-initialized .slick-slide {
      width: 300px !important;
      @media screen and (max-width: 576px) {
        width: 100vw !important;
      }
    }
    
    .slick-arrow {
      // border-radius: 8px;
      // background: rgba(0, 0, 0, 0.1);
      height: 100%;
      // padding: 0 20px;
      // display: none !important;
    }
    .slick-arrow:hover,
    .slick-arrow:focus {
      // background: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-prev:before {
      content: url(../../assets/images/icons/leftArrow.svg);
      position: relative;
      right: 10px;
    }
    .slick-next:before {
      content: url(../../assets/images/icons/arrow-right.svg);
      position: relative;
      right: 20px;
    }
    .slick-slider:hover .slick-arrow {
      display: block !important;
    }
    .slick-slide {
      @media screen and (max-width: 576px) {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
    .slick-arrow.slick-prev {
      position: absolute;
      z-index: 9;
      left: -10px !important;
      width: auto;
      @media screen and (max-width: 576px) {
        left: 20px !important;
      }
    }
    .slick-next {
      @media screen and (max-width: 576px) {
        right: 0 !important;
      }
    }
    .slick-prev {
      @media screen and (max-width: 576px) {
        left: 16px !important;
      }
    }
    // .slick-slide {
    //   width: auto !important;
    // }
  }
}
.newSection {
  padding-bottom: 80px;
}
.explore {
  padding-top: 0 !important;
}