.userDetail {
  .mainOuter {
    padding: 50px 0;
    .title {
      color: #000;
      font-size: 45px;
      font-weight: 700;
      @media screen  and (max-width: 575px){
        font-size: 30px;
      }
    }
    .content {
      padding-top: 100px;
      @media screen  and (max-width: 575px){
        padding-top: 30px;
      }
      .subTitle {
        color: #272727;
        font-size: 26px;
        font-weight: 700;
        margin: 0;
        @media screen  and (max-width: 575px){
          font-size: 20px;
        }
      }
      .desc {
        color: #909299;
        font-size: 16px;
        font-weight: 500;
      }
      .infoOuter {
        border-radius: 8px;
        border: 2px solid #e4e4e4;
        background: var(--shades-white, #fff);
        padding: 20px;
        width: 100%;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        @media screen  and (max-width: 575px){
          padding: 20px 10px;
        }
        .txt {
          color: #191d23;
          font-size: 18px;
          font-weight: 600;
          @media screen  and (max-width: 575px){
            font-size: 14px;
          }
        }
      }
      .check {
        display: none;
      }
      .selected {
        border: 2px solid #272727 !important;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
      .selected .check {
        display: block;
      }
      .blueBtn {
        border-radius: 10px;
        background: #0ea5e9;
        padding: 14px 40px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border: none;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
  .rightSection {
    border-radius: 8px;
    background: rgba(2, 104, 199, 0.13);
    padding: 23px;
    height: 100%;
    .heading {
      color: #0268C7;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .description {
      color: #0268C7;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
