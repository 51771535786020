.mainOuter {
  padding: 50px 0;

  .banner {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }

  .title {
    color: #000;
    font-size: 22px;
    font-weight: 700;

    @media screen and (max-width: 1399px) {
      margin: 0;
    }

    @media screen and (max-width: 767px) {
      font-size: 30px;
    }
  }

  .infoTxt {
    text-align: center;
    color: #4f81bd;

    h1 {
      font-size: 23px;
      font-weight: 800;
      color: #4f81bd;
      margin-bottom: 0;
    }

    p {
      font-weight: 800;
    }
  }

  .content {
    padding: 40px 20px 0;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 50px;

    @media screen and (max-width: 1399px) {
      padding-top: 50px;
    }

    @media screen and (max-width: 400px) {
      padding-top: 20px;
    }

    .subTitle {
      color: #272727;
      font-size: 26px;
      font-weight: 700;
      margin: 0;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .desc {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .input {
      background-color: initial;
      border: 1px solid #ccc;
      border-radius: 6px;
      outline: 0;
      padding: 12px;
      width: 100%;
    }

    .selectInput>div {
      // height: 46px !important;
      padding: 2px 0px !important;
    }

    .missionStatementInput {
      background-color: initial;
      border: 1px solid #ccc;
      border-radius: 6px;
      outline: 0;
      padding: 12px;
      width: 100%;
      height: 6rem;
    }

    .blueBtn {
      border-radius: 10px;
      background: #0ea5e9;
      padding: 14px 40px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      border: none;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding-bottom: 10px;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

    .btnOuter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;

      .blueBtn {
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }

      .backBtn {
        background-color: #e0e0e0;
        color: #000;
      }
    }
  }

  :global {
    .ant-picker {
      width: 30%;
      background-color: transparent;
      border-radius: 6px;
      padding: 10px;
    }
  }
}