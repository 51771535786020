.listing {
  padding: 100px 0;
  background-color: #f5f7fa;
  .title {
    color: #000;
    font-size: 40px;
    font-weight: 600;
    @media screen and (max-width: 575px) {
      font-size: 30px;
    }
  }
  .subtitle {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .desc {
    color: #777e90;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .nftCard {
    width: 312px;
    border-radius: 12px;
    background-color: #fff;
    cursor: pointer;
    @media screen and (max-width: 1199px) {
      width: auto;
    }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    img {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: contain;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: 250px;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        height: 200px;
      }
    }
    .previewimg {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin: 0;
      width: 312px;
      height: 300px;
      object-fit: fill;
      background-color: #e6e6e6;
      @media screen and (max-width: 1199px) {
        width: 100%;
        height: auto;
      }
      img, video {
        width: 312px;
        height: 300px;
        object-fit: contain;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        @media screen and (max-width: 1199px) {
          width: 100%;
          height: auto;
        }
      }
    }

    .cardInfo {
      padding: 16px;
      .priceBorder {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px 10px;
      }
    }
    .price {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
    .previewTxt {
      background-color: #e6e6e6;
      height: 20px;
      width: 150px;
      display: block;
      border-radius: 10px;
    }
    .subTxt {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .sideTitle {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 26px;
  }
  .label {
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 6px;
  }
  .radioGroup {
    border: 1px solid #ccc;
    border-radius: 12px;
    margin-bottom: 30px;
  }
  .boldtxt {
    color: #2e2e2e;
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.6;
  }
  .border_bottom {
    border-bottom: 1px solid #ccc;
  }
  .metaTitle {
    color: #2e2e2e;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 25px;
    .textDanger {
      color: "red";
    }
  }

  .formInput {
    margin-bottom: 30px;
    input {
      width: 100%;
      outline: 0;
      background-color: transparent;
      border-radius: 10px;
      border: 1px solid #ccc;
      padding: 12px;
    }
    ::placeholder {
      color: #17181a;
      font-size: 16px;
      font-weight: 400;
      opacity: 0.3;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /* Disable scrolling behavior */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .subinfo {
      color: #2e2e2e;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .outer {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 12px;
    display: flex;
    input {
      width: 100%;
      outline: 0;
      background-color: transparent;
      border: 0;
      padding-right: 12px;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    /* Disable scrolling behavior */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .summary {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
    margin: 20px 0 10px 0;
  }
  .value {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 500;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding-bottom: 10px;
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    .btnOuter {
      display: flex;
      justify-content: flex-end;
    }
  }
  .blueBtn {
    border-radius: 10px;
    background: #0ea5e9;
    backdrop-filter: blur(13px);
    color: #fafafa;
    font-size: 16px;
    font-weight: 600;
    border: none;
    padding: 12px 30px;
    margin-top: 10px;
    border: none;
    &:hover {
      opacity: 0.8;
      color: #fff;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
  :global {
    .ant-radio {
      position: absolute;
      right: 0;
    }
    .ant-select,
    .ant-select-selector {
      height: 52px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      color: #121212;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 12px;
      background: transparent;
      border-radius: 10px;
      border: 1px solid #ccc;
      color: #121212;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      height: 52px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 10px;
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .donation {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.02);
    padding: 0 20px;
    border-radius: 10px;
  }
}
:global {
  .ant-modal-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
  }
}
.orgModal {
  .inner {
    .formInput {
      margin-bottom: 30px;
      .label {
        color: #2e2e2e;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
      }
      input {
        border-radius: 6px;
        border: 1px solid #ccc;
        padding: 12px;
        background-color: transparent;
        width: 100%;
        outline: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      /* Disable scrolling behavior */
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .blueBtn {
      border-radius: 10px;
      background: #0ea5e9;
      backdrop-filter: blur(13px);
      color: #fafafa;
      font-size: 16px;
      font-weight: 600;
      border: none;
      padding: 12px 30px;
      margin-top: 10px;
      border: none;
      width: 100%;
      &:hover {
        opacity: 0.8;
        color: #fff;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
