.nftCard {
  padding: 14px;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 7.77673px 11.66509px 0px rgba(30, 30, 30, 0.04);
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .nftImg,
  video {
    height: 270px;
    width: 100%;
    object-fit: contain;
  }
  &:hover .nftImg,
  video {
    transform: scale(1.03);
    transition: 0.3s ease-in-out;
    margin-bottom: 0.75rem;
  }
  .name {
    color: #131516;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .desc {
    color: #4a5568;
    font-size: 12px;
    font-weight: 500;
  }
  .txt {
    color: #131516;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .price {
    color: #029cf5;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}
.pubnftCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 21px;
  border: 1px solid #e9e9e9;
  background: #fff;
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.05);
  padding: 30px 10px 10px;
  gap: 20px;
  height: 270px;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  &:hover .img {
    transform: scale(1.15);
    transition: 0.3s ease-in-out;
  }
  .img {
    height: 140px;
    border-radius: 10px;
  }
  .txt {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    word-break: break-word;
  }
}
.videoIcon {
  width: 35px;
  height: 32px;
  margin-right: 0;
  margin-left: 33px;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: 9;
  background: rgba(2, 156, 245, 0.85);
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 18px;
    path {
      fill: #fff;
    }
  }
  &:hover svg {
    path {
      fill: #fff;
    }
  }
}
