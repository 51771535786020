.mainCard {
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 7.86667px 11.8px 0px rgba(30, 30, 30, 0.04);
  padding: 12px;
  position: relative;
  height: 492px;
  width: 300px;
  @media screen and (max-width: 1399px) {
    width: auto;
  }
  @media screen and (max-width: 991px) {
    width: 300px;
  }
  @media screen and (max-width: 767px) {
    width: 235px;
  }
  @media screen and (max-width: 576px) {
    width: 80vw;
  }
  @media screen and (max-width: 400px) {
    width: 80vw;
  }
  .mainImg,
  video {
    width: 100%;
    height: 290px;
    border-radius: 7px;
    object-fit: contain;
    position: relative;
    &:hover {
      transform: scale(1.05);
      transition: 0.3s ease-in-out;
    }
  }
  .causeImg {
    border-radius: 100%;
    height: 26px;
    width: 26px;
    border: 1px solid #ccc;
  }
  .plusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: #abb0b5;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
  }
  .plusIconCateg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 24px;
    background-color: #029cf5;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .actionBtn {
    position: absolute;
    top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    visibility: visible;
    .bg {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.23);
      backdrop-filter: blur(8px);
      padding: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.85;
      }
    }
    .graph {
      margin-left: 10px;
    }
    .heart {
      margin-right: 10px;
      height: 32px;
      border-radius: 10px;
      background: rgba(2, 156, 245, 0.85);
      backdrop-filter: blur(8px);
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 0;
      top:3px;
      svg {
        height: 22px;
      }
      &:hover {
        opacity: 0.95;
      }
      &:hover svg {
        path {
          fill: red;
        }
      }
    }
    .heartActive {
      margin-right: 10px;
      height: 32px;
      border-radius: 10px;
      background: #029cf5;
      backdrop-filter: blur(8px);
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top:3px;
      svg {
        height: 22px;
        path {
          fill: red;
        }
      }
    }
    .videoIcon {
      width: 35px;
      height: 32px;
      margin-right: 0;
      margin-left: 10px;
      position: absolute;
      left: 0;
      top:3px;
      svg {
       font-size: 18px;
        path {
          fill: #fff;
        }
      }
      &:hover svg {
        path {
          fill: #fff;
        }
      }
    }
    .txt {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .userContainer {
    // position: relative;
    position: absolute;
    bottom: 94px;
    // top: -25px;
    left: 10px;
    .user {
      border-radius: 100%;
      border: 1.9px solid #d4ddea;
      height: 40px;
      // width: 40px;
      width: 50px;
      margin-left: -12px;
      object-fit: cover;
    }
  }
  .title {
    color: #131516;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    cursor: pointer;
    &:hover {
      color: #333436;
    }
  }
  .desc {
    color: #4a5568;
    font-size: 12px;
    font-weight: 500;
    word-break: break-word;
  }
  .owner {
    color: #029cf5;
    font-size: 13px;
  }
  .priceTxt {
    color: #131516;
    font-size: 16px;
    font-weight: 600;
  }
  .price {
    color: #029cf5;
    font-size: 16px;
    font-weight: 600;
  }
  .category {
    cursor: pointer;
    background-color: #029cf5;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 5px;
    border: 1px solid #029cf5;
  }
  .priceOuter {
    position: absolute;
    width: 92%;
    bottom: 12px;
  }
}
