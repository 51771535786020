.uploadProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .imgUpload {
    height: 240px;
    width: 240px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    border: 1px solid #ccc;
    padding: 5px;
    overflow: hidden;
    @media screen and (max-width: 576px) {
      height: 200px;
      width: 200px;
    }
  }
  .uploadTxt {
    text-align: center;
    color: #0ea5e9;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .inputFile {
    position: absolute;
    top: 0;
    left: -62px;
    opacity: 0;
    cursor: pointer;
  }
  .imgesOuter {
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    background: #fff;
    @media screen and (max-width: 1199px) {
      padding: 10px;
    }
    .shortImg {
      height: 46px;
      width: 100%;
      &:hover {
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
    }
  }
  .tab {
    color: #4e4e51;
    font-size: 13px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    @media screen and (max-width: 1199px) {
      font-size: 12px;
    }
  }
  .activeTab {
    color: #0ea5e9;
    font-size: 13px;
    font-weight: 700;
    text-decoration-line: underline;
    background-color: transparent;
    border: none;
    text-underline-offset: 2px;
  }
  .images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    cursor: pointer;
    img {
      height: 46px;
      width: 46px;
    }
    @media screen and (max-width: 1199px) {
      grid-gap: 10px;
    }
  }
  :global {
    .ant-upload-list.ant-upload-list-picture-card {
      display: flex;
      flex-direction: column;
    }
    .ant-upload {
      color: #0ea5e9;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
    }
    .ant-upload-picture-card-wrapper {
      width: 100%;
    }
    // .ant-upload-list.ant-upload-list-picture-card {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    .ant-upload.ant-upload-select-picture-card {
      width: 30%;
      height: 40px;
      text-align: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: border-color 0.3s;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
      border-color: transparent;
    }
    .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
      width: 240px;
      height: 240px;
      border: none;
      border-radius: 100%;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      border-radius: 100%;
    }
  }
}
