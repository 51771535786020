.featuredCard {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8.42857px 12.64286px 0px rgba(30, 30, 30, 0.04);
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    &:hover .nftImg {
        transform: scale(1.04);
        transition: 0.3s ease-in-out;
    }

    .nftImg {
        width: 100%;
        height: 280px;
        object-fit: contain;
        margin-bottom: 8px;
    }

    .subImg {
        height: 110px;
        object-fit: contain;
    }

    .userImg {
        height: 40px;
        min-width: 40px;
        object-fit: cover;
        border-radius: 100%;
        border: 2px solid #D4DDEA;
    }

    .varified {
        position: absolute;
        bottom: 0;
        left: 25px;
        height: 20px;
    }

    .title {
        color: #131516;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .desc {
        color: #4A5568;
        font-size: 12px;
        font-weight: 500;
    }

    .rating {
        background: #F5F7FA;
        border-radius: 30px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        width: 80px;
        cursor: pointer;

        span {
            color: #718096;
            font-size: 16px;
            font-weight: 700;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.explore {
    padding-top: 0 !important;
}
.videoContainer {
    width: 35px;
    height: 32px;
    margin-right: 0;
    margin-left: 15px;
    position: absolute;
    left: 0;
    top: 15px;
    z-index: 9;
    background: rgba(2, 156, 245, 0.85);
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 18px;
      path {
        fill: #fff;
      }
    }
    &:hover svg {
      path {
        fill: #fff;
      }
    }
  }