.mainOuter {
  padding: 50px 0;
  .title {
    color: #000;
    font-size: 45px;
    font-weight: 700;
    @media screen and (max-width: 1399px) {
      margin: 0;
    }
    @media screen and (max-width: 767px) {
     font-size: 30px;
    }
  }
  .content {
    padding-top: 100px;
    @media screen and (max-width: 1399px) {
      padding-top: 50px;
    }
    @media screen and (max-width: 400px) {
      padding-top: 20px;
    }
    .subTitle {
      color: #272727;
      font-size: 26px; 
      font-weight: 700;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 20px;
       }
    }
    .desc {
      color: #909299;
      font-size: 16px;
      font-weight: 500;
    }
    .box {
      width: 310px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      border: 2px solid #e4e4e4;
      border-radius: 8px;
    background-color: #fff;

      cursor: pointer;
      position: relative;
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
      .subHeading {
        color: #191d23;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }
      .txt {
        color: #64748b;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
    .check {
      position: absolute;
      top: 15px;
      right: 15px;
      display: none;
    }
    .selected .check {
      display: block;
    }
    .selected {
      border: 2px solid #272727 !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .blueBtn {
      border-radius: 10px;
      background: #0ea5e9;
      padding: 14px 40px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      border: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
