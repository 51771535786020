.causeDetailComp {
    padding: 100px 0 0 0;
    .title {
      color: #000;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 10px;
      @media screen and (max-width: 576px) {
        font-size: 20px;
      }
    }
     .blueBtn {
      border-radius: 8px;
      background: transparent;
      border: 1px solid #029cf5 !important;
      color: #029cf5;
      font-size: 14px;
      font-weight: 600;
      border: 0;
      padding: 5px 15px;
    }
    .searchIcon {
      cursor: pointer;
    }
    .causeOuter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 21px;
      border: 1px solid #e9e9e9;
      background: #fff;
      box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.05);
      padding: 30px 10px 10px;
      gap: 20px;
      height: 270px;
      cursor: pointer;
      &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }
      &:hover .img {
        transform: scale(1.15);
        transition: 0.3s ease-in-out;
      }
      .img {
        height: 140px;
        border-radius: 10px;
      }
      .txt {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        word-break: break-word;
        margin-bottom: 0;
      }
    }
    :global {
      .ant-select,
      .ant-select-selector {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-select-selection-placeholder {
        color: #121212;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.1);
        color: #121212;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        height: 52px;
      }
    }
     .check {
      margin-bottom: 0;
    }
    // .check {
    //   display: none;
    // }
    // .selected .check {
    //   display: block;
    // }
    // .selected {
    //   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // }
  }
  